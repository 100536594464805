import { MUDChain, latticeTestnet } from "@latticexyz/common/chains";
import { foundry } from "@wagmi/chains";
const baseTestnet = {
    name: "Base Testnet",
    id: 84531,
    network: "base-testnet",
    nativeCurrency: { decimals: 18, name: "Ether", symbol: "ETH" },
    rpcUrls: {
      default: {
        http: ["https://base-goerli.blastapi.io/cb17af41-ef9a-4aee-997a-c5248d1bce04"],
        webSocket: ["wss://base-goerli.blastapi.io/cb17af41-ef9a-4aee-997a-c5248d1bce04"],
      },
      public: {
        http: ["https://base-goerli.blastapi.io/cb17af41-ef9a-4aee-997a-c5248d1bce04"],
        webSocket: ["wss://base-goerli.blastapi.io/cb17af41-ef9a-4aee-997a-c5248d1bce04"],
      },
    },
    modeUrl: "",
    faucetUrl: "https://bwarelabs.com/faucets/base-testnet",
  } as const satisfies MUDChain;
// If you are deploying to chains other than anvil or Lattice testnet, add them here
export const supportedChains: MUDChain[] = [foundry, latticeTestnet,baseTestnet];
